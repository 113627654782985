@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



#root {
    position: relative;
    font-family: 'Montserrat', sans-serif;
}
.hillsBackground {
    background-image: url("./assets/background.svg");
    min-width: 100%;
    min-height: 100%;
    /* overflow: hidden; */
    /* aspect-ratio: 1920/1080; */
    background-size: auto auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -20;
}


/* ensures the increment/decrement arrows always display */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}


/* hide them with this */
input[type=number].hideInputArrows::-webkit-inner-spin-button , 
input[type=number].hideInputArrows::-webkit-outer-spin-button {
  opacity: 0;
  display: none;
}


/* scroll styles */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f5f9fc;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  border-radius: 50px;
}


.ql-container {
  border: none !important;
}

.ql-editor {
  height: 7rem !important;
}